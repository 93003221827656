import { useEffect, useState, useCallback } from "react";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { fetchItemMetric } from '../../api/metrics';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);


function InfusionCalculator() {
    document.title = 'Trade Track - Infusion Calculator';

    const [infusions, setInfusions] = useState([]);
    const [fetching, setFetching] = useState(true);
    const [detailed, setDetailed] = useState(false);

    const loadItemMetric = useCallback(async (id) => {
        let world = localStorage.getItem('world');
        let data = await fetchItemMetric(id, world)
        // remove last item
        if (data.pricing === undefined) {
            return { found: false };
        }
        data.pricing.pop();
        // get last 7 items
        data.pricing = data.pricing.slice(-7);
        return data;
    }, []);

    useEffect(() => {
        // get from get params
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('detailed') && urlParams.get('detailed') === 'true') {
            setDetailed(true);
        }
        const fetchData = async () => {
            let world = localStorage.getItem('world');

            const response = await fetch(`${process.env.REACT_APP_TRADEPACK_API}/infusionsBeta?world=${world}`);
            const data = await response.json();

            const promises = data.map(async (infusion) => {
                infusion.taxToInfuse = infusion.exp * 2;
                let baseValue = infusion.medianPrice;
                if (infusion.quantity) {
                    baseValue = infusion.medianPrice * infusion.quantity;
                }
                baseValue = baseValue + infusion.taxToInfuse;

                if (infusion.taxToCraft) {
                    baseValue = baseValue + infusion.taxToCraft;
                }
                let expPerSilver = baseValue / infusion.exp;
                infusion.expPerSilver = expPerSilver;

                infusion.popover = (
                    <Popover id="popover-basic">
                        <Popover.Header as="h3">Info</Popover.Header>
                        <Popover.Body>
                            {infusion.quantity && <p>Price is calculated using a quantity of {infusion.quantity} to craft a T1 item to infuse</p>}
                            {infusion.taxToCraft && <p>Tax to craft: {infusion.taxToCraft} silver</p>}
                            <p>Tax to infuse: {infusion.taxToInfuse} silver</p>
                            <p>EXP: {infusion.exp}</p>
                        </Popover.Body>
                    </Popover>
                );

                if (infusion.market) {
                    const metrics = await loadItemMetric(infusion.id);
                    if (!metrics.found) {
                        return infusion;
                    }

                    infusion.metrics = metrics;
                    // console.log(infusion.name, infusion.metrics)
                    let prices = [];
                    let labels = [];
                    let mediam = 0;

                    let cont = 1;
                    for (const metric of infusion.metrics.pricing) {
                        // metric.price += infusion.taxToInfuse;
                        prices.push(metric.price);

                        mediam += metric.price;

                        labels.push(cont);
                        cont++;
                    }

                    mediam = mediam / infusion.metrics.pricing.length;
                    infusion.median = mediam;

                    // diff of last price and median
                    let lastPrice = infusion.medianPrice;
                    let diff = lastPrice - mediam;
                    // percentage of diff
                    let percentage = (lastPrice * 100) / mediam - 100;

                    infusion.diff = diff;
                    infusion.percentage = percentage;
                    infusion.lastPrice = lastPrice;

                    // add last price
                    prices.push(infusion.medianPrice);
                    labels.push(cont);

                    const lineData = {
                        labels,
                        datasets: [
                            {
                                drawActiveElementsOnTop: false,
                                fill: true,
                                pointStyle: false,
                                tension: 0.4,
                                label: 'Median Price',
                                data: prices,
                                borderColor: 'rgb(53, 162, 235)',
                                backgroundColor: 'rgba(53, 162, 235, 0.5)',
                            }
                        ],
                    };

                    infusion.lineData = lineData;
                }




                return infusion;
            });

            const updatedData = await Promise.all(promises);

            updatedData.sort((a, b) => {
                if (a.expPerSilver === 0) return 1;
                if (b.expPerSilver === 0) return -1;
                if (a.medianPrice === 0) return 1;
                if (b.medianPrice === 0) return -1;
                return a.expPerSilver - b.expPerSilver;
            });

            setInfusions(updatedData);
            setFetching(false);
        };

        fetchData();
    }, [loadItemMetric]);

    if (fetching) {
        return <div>Loading...</div>;
    }

    const thousandSeparator = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    const captalizeAll = (str) => {
        return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
    }


    const last7DaysTemplate = (rowData) => {
        const options = {
            responsive: true,
            animation: false,
            layout: {
                padding: 0
            },
            datasets: {
                line: {
                    pointRadius: 0 // disable for all `'line'` datasets
                }
            },
            plugins: {
                legend: {
                    position: 'top',
                    display: false
                },
                title: {
                    display: false,
                    text: 'Median Price',
                },
                tooltip: {
                    enabled: false
                }
            },
            scales: {
                y: {
                    suggestedMin: 0,
                    display: false
                },
                x: {
                    display: false
                }
            }
        };

        return (
            <div style={{ display: "flex", alignItems: "center", flexDirection: "column-reverse" }}>
                <div style={{ height: "55px" }}>
                    <Line options={options} data={rowData.lineData} />
                </div>
                <OverlayTrigger placement="left" overlay={(
                    <Popover id="popover-basic">
                        <Popover.Body>
                            Last 7 days median price: {rowData.median.toFixed(0)}<br />
                        </Popover.Body>
                    </Popover>
                )}>

                    <span className={rowData.diff > 0 ? 'text-danger' : 'text-success'}> {rowData.diff > 0 ? '+' : ''}{rowData.percentage.toFixed(2)}%</span>
                </OverlayTrigger>
            </div>
        )
    }

    const materialsTemplate = (materials) => {
        // sort materiasl by price
        materials.sort((a, b) => {
            return b.price - a.price;
        });
        return (
            <div style={{ fontSize: '14px' }}>
                {materials.map((material) => (
                    <div key={material.id}>
                        <p className="card-text text-center" style={{ marginBottom: '2px' }}>{captalizeAll(material.name)} ({material.amount}): {thousandSeparator(parseInt(material.price))} ({thousandSeparator(parseInt(material.pricePerItem))} per unt) </p>
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div className="row" id="infusions">
            {infusions.map((infusion) => (
                <div className="col-lg-3 col-6 card" style={{ marginBottom: '10px' }} key={infusion.id}>
                    <div class="card-body" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                        <p className="text-center" style={{ marginBottom: '5px' }}>
                            <img className="img-fluid" src={infusion.url} width={25} height={25} alt={infusion.name} />
                            {infusion.name}
                        </p>
                        <p className="card-text text-center" style={{ marginBottom: '2px' }}>Median Price: {thousandSeparator(parseInt(infusion.medianPrice))}</p>

                        {infusion.materials && detailed && materialsTemplate(infusion.materials)}

                        <OverlayTrigger placement="bottom" overlay={infusion.popover}>
                            <p className="card-text text-center" style={{ marginBottom: '2px', cursor: 'help' }}>Silver per EXP: {infusion.expPerSilver.toFixed(2)}</p>
                        </OverlayTrigger>
                        {
                            (infusion.metrics && infusion.metrics.found) ?
                                last7DaysTemplate(infusion) : ''
                        }
                    </div>
                </div>
            ))}
        </div>
    );
}

export default InfusionCalculator;
