import { useEffect, useState } from "react";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { fetchItemMetric } from '../../api/metrics';
import './style.css';
import Creatable from 'react-select/creatable';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function BestRoutesSimulator() {
  document.title = 'Trade Track - Top 5';
  const [finishedLoading, setFinishedLoading] = useState(false);
  const [tradepacks, setTradepacks] = useState([]);
  const [metricData, setMetricData] = useState({});
  const [materials, setMaterials] = useState({});
  // 5% bonus
  const [bonus5, setBonus5] = useState(false);
  // 10% bonus
  const [bonus10, setBonus10] = useState(false);
  // typeChannel
  const [typeChannel, setTypeChannel] = useState('normal');
  // certificate
  const [certificate, setCertificate] = useState(false);
  // tradepackCount
  const [tradepackCount, setTradepackCount] = useState(1);
  // visualizeOption
  const [visualizeOption, setVisualizeOption] = useState('all');
  // prices
  const [pricesOption, setPricesOption] = useState('live');
  // refresh
  const [refresh, setRefresh] = useState(false);

  const [crafting, setCrafting] = useState({});

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#333',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#444', // Cor de fundo ao passar o mouse
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#333',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#fff',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#555' : '#333',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#444', // Cor de fundo ao passar o mouse
      },
    }),
  };



  async function onChangeMaterialAmount(event) {
    let tradepackId = parseInt(event.target.getAttribute('data-tradepack'));
    let materialId = parseInt(event.target.getAttribute('data-material'));
    let amount = parseInt(event.target.value);
    let tradepacksCopy = [...tradepacks];

    let tradepack = tradepacksCopy.find((tradepack) => tradepack.id === tradepackId);
    let material = tradepack.materials.find((material) => material.clientId === materialId);
    material.amount = amount;

    // setTradepacks(tradepacksCopy);
    tradepacksCopy = await processTradepacks(tradepacksCopy);
    setTradepacks(tradepacksCopy);
    // focus the input
    // the input is destroyed and recreated on every render, find using the name attribute
    let input = document.querySelector(`input[name="material_${tradepackId}_${materialId}"]`);
    if (input) {
      input.focus();
    }

  }

  // onCheckboxChange
  function onCheckboxChange(event) {
    let id = event.target.id;
    let checked = event.target.checked;

    if (id === '5_bonus') {
      setBonus5(checked);
    } else if (id === '10_bonus') {
      setBonus10(checked);
    } else if (id === 'certificate') {
      setCertificate(checked);
    }
  }

  function onVisualizeOptionChange(event) {
    let value = event.target.value;
    setVisualizeOption(value);
  }

  function onPricesOptionChange(event) {
    let value = event.target.value;
    setPricesOption(value);
  }

  function onTypeChannelChange(event) {
    let value = event.target.value;
    setTypeChannel(value);
  }

  function onRefresh(event) {
    setRefresh(!refresh);
  }

  async function removeMaterial(event) {
    let tradepackId = parseInt(event.target.getAttribute('data-tradepack'));
    let materialId = parseInt(event.target.getAttribute('data-material'));
    let tradepacksCopy = [...tradepacks];

    let tradepack = tradepacksCopy.find((tradepack) => tradepack.id === tradepackId);
    // remove material
    tradepack.materials = tradepack.materials.filter((material) => material.clientId !== materialId);

    // setTradepacks(tradepacksCopy);
    tradepacksCopy = await processTradepacks(tradepacksCopy);
    setTradepacks(tradepacksCopy);
  }

  async function clickNewMaterial(event) {
    let tradepackId = parseInt(event.target.getAttribute('data-tradepack'));
    let tradepacksCopy = [...tradepacks];
    let tradepack = tradepacksCopy.find((tradepack) => tradepack.id === tradepackId);
    tradepack.newMaterial = true
    setTradepacks(tradepacksCopy);
  }

  async function addMaterial(event) {
    try {
      debugger
      // disable button
      event.target.disabled = true;

      let tradepackId = parseInt(event.target.getAttribute('data-tradepack'));
      let tradepacksCopy = [...tradepacks];
      let tradepack = tradepacksCopy.find((tradepack) => tradepack.id === tradepackId);

      let select = document.querySelector(`[name="newMaterial_${tradepackId}"]`);

      let custom =  isNaN(parseInt(select.value));
      
      if (!custom) {
        let materialId = parseInt(select.value);
        if (!materialId) {
          return;
        }
        let material = materials[materialId];

        let materialHistory = await loadItemMetric(material.id);

        if (materialHistory.found) {
          material.metrics = materialHistory;
        }

        // check if exists this material in another tradepack, if exists copy pricing
        for (const tradepack of tradepacksCopy) {
          let found = tradepack.materials.find((material) => material.clientId === materialId);
          if (found) {
            material.pricing = found.pricing;
            break;
          }
        }

        let minLevel = materials[materialId] ? materials[materialId].minLevel : 1;

        if (material.metric) {
          material.pricing = {
            medianPrice: material.metrics ? material.metrics.pricing[material.metrics.pricing.length - 1].price : 0,
            lastUpdated: new Date(),
            smallestPrice: 0,
            highestPrice: 0,
          }
        } else {
          // find in crafting
          let craft = crafting[materialId];

          if (craft) {
            debugger
            material.pricing = {
              medianPrice: craft.pricePerItem,
              lastUpdated: new Date(),
              smallestPrice: 0,
              highestPrice: 0,
            }
            minLevel = craft.level ? craft.level.single : 1;
          } else {
            material.pricing = {
              medianPrice: 0,
              lastUpdated: new Date(),
              smallestPrice: 0,
              highestPrice: 0,
            }
          }
        }

        tradepack.materials.push({
          clientId: materialId,
          name: material.name,
          image: material.image,
          pricing: material.pricing,
          metrics: material.metrics,
          minLevel: minLevel,
          amount: 1
        });

        tradepack.newMaterial = false;
        tradepacksCopy = await processTradepacks(tradepacksCopy);
        setTradepacks(tradepacksCopy);
      } else {
        let material = {
          id: 0,
          name: select.value,
          image: undefined,
          minLevel: 1,
          pricing: {
            medianPrice: 0,
            lastUpdated: new Date(),
            smallestPrice: 0,
            highestPrice: 0,
          },
          metrics: {
            pricing: []
          },
          amount: 1,
          custom: true,
        }

        tradepack.materials.push(material);

        tradepack.newMaterial = false;

        tradepacksCopy = await processTradepacks(tradepacksCopy);
        setTradepacks(tradepacksCopy);
      }
    } catch (error) {
      console.error(error);
    } finally {
      // enable button
      event.target.disabled = false;
    }
  }

  async function clickNewTradepack(event) {
    let tradepacksCopy = [...tradepacks];
    let newTradepackName = document.querySelector('#newTradepackName').value;
    if (!newTradepackName) {
      return;
    }

    // let first tradepack
    let firstTradepack = tradepacksCopy[0];

    // add to tradepacks
    let tradepack = {
      id: tradepacksCopy.length + 1,
      name: newTradepackName,
      image: 'https://cdn-wiki.ravendawn.online/Tradepack.png',
      price: 0,
      materials: [],
      tradePosts: firstTradepack.tradePosts,
      bestRoutes: firstTradepack.bestRoutes
    };

    tradepacksCopy.push(tradepack);
    setTradepacks(tradepacksCopy);

    // clear input
    document.querySelector('#newTradepackName').value = '';
  }


  async function loadItemMetric(id) {
    let world = localStorage.getItem('world');
    let data = await fetchItemMetric(id, world);

    if (data.pricing) {
      data.pricing.pop();
      // data.pricing = data.pricing.slice(-7);
    }
    return data;
  }

  async function processTradepacks(tradepacks) {

    let seaTradeposts = [
      4, 7, 3, 8, 2, 9
    ];
    let landTradeposts = [
      11, 6, 5, 1, 9, 2, 10, 12
    ];

    let bonus = 0;

    if (bonus5) {
      bonus += 0.05;
    }

    if (bonus10) {
      bonus += 0.10;
    }

    if (typeChannel === 'warmode') {
      bonus += 0.15;
    } else if (typeChannel === 'plunder') {
      bonus += 0.25;
    }

    for (const tradepack of tradepacks) {
      let price = 0;
      for (const material of tradepack.materials) {
        let amount = material.amount;
        if (pricesOption === 'live') {
          if (material.pricing) {

            price += material.pricing.medianPrice * amount * tradepackCount;
            material.usingPrice = material.pricing.medianPrice * amount * tradepackCount;
            material.median = material.pricing.medianPrice;
          } else {
            price += 0;
            material.usingPrice = 0;
            material.median = 0;
          }

        } else if (pricesOption === '7d' || pricesOption === '30d') {
          let days = 7;
          if (pricesOption === '30d') {
            days = 30;
          }
          let median = 0;
          // get the last 7 days
          let metrics = material.metrics.pricing.slice(-days);
          for (const metric of metrics) {
            median += metric.price;
          }
          median = median / metrics.length;
          price += median * amount * tradepackCount;
          material.usingPrice = median * amount * tradepackCount;
          material.median = median;
        }
      }

      tradepack.price = price;

      tradepack.best_profit = 0;
      for (const tradePost of tradepack.tradePosts) {
        for (const route of tradePost.routes) {
          let routePrice = 0;
          let demand = route.demand;

          for (let i = 1; i <= tradepackCount; i++) {
            routePrice += route.priceBase * (demand / 100) * (1 + bonus);
            demand -= 1;
          }

          route.price = routePrice;

          if (visualizeOption !== 'all') {
            if (visualizeOption === 'only_sea' && !(seaTradeposts.includes(tradePost.tradepostId) && seaTradeposts.includes(route.tradepostId))) {
              continue;
            } else if (visualizeOption === 'ignore_sea' && !(landTradeposts.includes(tradePost.tradepostId) && landTradeposts.includes(route.tradepostId))) {
              continue;
            }
          }
          let profit = 0;
          if (certificate) {
            profit = route.price
          } else {
            profit = route.price - tradepack.price;
          }
          if (profit > tradepack.best_profit) {
            tradepack.best_profit = profit;
          }
        }
      }

      let bestRoutes = [];

      for (const tradePost of tradepack.tradePosts) {
        for (const route of tradePost.routes) {

          if (visualizeOption !== 'all') {
            if (visualizeOption === 'only_sea' && !(seaTradeposts.includes(tradePost.tradepostId) && seaTradeposts.includes(route.tradepostId))) {
              continue;
            } else if (visualizeOption === 'ignore_sea' && !(landTradeposts.includes(tradePost.tradepostId) && landTradeposts.includes(route.tradepostId))) {
              continue;
            }
          }

          bestRoutes.push({
            routeName: tradePost.name + ' -> ' + route.tradepostName,
            distance: route.distance,
            distanceDijkstra: route.distanceDijkstra,
            tradepost: tradePost,
            tradepostName: route.tradepostName,
            demand: route.demand,
            price: route.price
          });
        }
      }
      bestRoutes.sort((a, b) => {
        return b.price - a.price;
      });

      bestRoutes = bestRoutes.slice(0, 5);

      tradepack.bestRoutes = bestRoutes;
    }

    tradepacks.sort((a, b) => {
      return b.best_profit - a.best_profit;
    })
    setTradepacks(tradepacks);
    return tradepacks;
  }

  async function getMaterials() {
    const professions = ["Husbandry","Farming","Woodcutting","Mining","Alchemy","Blacksmithing","Carpentry","Cooking","Fishing","Herbalism","Weaving"];

    let materialsItems = {};

    for (const profession of professions) {
      const response = await fetch(`${process.env.REACT_APP_TRADEPACK_API}/v2/recipes/${profession}`);
      const data = await response.json();

      for (const recipe of data) {
        let items = [];
        if (recipe.items) {
          items = items.concat(recipe.items);
        }
        if (recipe.butchering) {
          items = items.concat(recipe.butchering);
        }
        if (recipe.gathering) {
          items = items.concat(recipe.gathering);
        }
        if (recipe.materials) {
          items = items.concat(recipe.materials);
        }

        for (const item of items) {
          let itemId = item.itemid || item.id;
          if (!materialsItems[itemId]) {
            if (item.name) {
              materialsItems[itemId] = {
                id: itemId,
                name: item.name.toLowerCase(),
                image: `https://cdn.tradetrack.online/items/${itemId}.png`,
                minLevel: recipe.skillRequired
              }

            }
          } else {
            if (materialsItems[itemId].minLevel > recipe.skillRequired || materialsItems[itemId].minLevel === undefined) {
              materialsItems[itemId].minLevel = recipe.skillRequired;
            }
          }
        }

        // check if recipe is in materials, if not add
        if (!materialsItems[recipe.itemid]) {
          materialsItems[recipe.itemid] = {
            id: recipe.itemid,
            name: recipe.name.toLowerCase(),
            image: `https://cdn.tradetrack.online/items/${recipe.itemid}.png`,
            minLevel: recipe.level ? recipe.level.single : 1
          }
        } else {
          if (materialsItems[recipe.itemid].minLevel > recipe.skillRequired || materialsItems[recipe.itemid].minLevel === undefined) {
            materialsItems[recipe.itemid].minLevel = recipe.skillRequired;
          }
        }
      }
    }
    return materialsItems;
  }

  async function loadCrafting() {
    const response = await fetch(`${process.env.REACT_APP_TRADEPACK_API}/crafting?world=angerhorn`);
    const data = await response.json();

    let crafting = {};
    for (const craft of data) {
      crafting[craft.itemid] = craft;
    }

    return crafting;
  }

  useEffect(() => {
    async function fetchData() {
      let world = localStorage.getItem('world');

      if (tradepacks.length === 0) {
        setFinishedLoading(false);

        const response = await fetch(`${process.env.REACT_APP_TRADEPACK_API}/tradepacksDijkstra?world=${world}`);
        const data = await response.json();

        let crafting = await loadCrafting();

        let globalMaterials = await getMaterials();

        setMaterials(globalMaterials);

        let tradepacks = [];

        for (const tradepack of data) {
          let price = 0;
          let globalDemand = (tradepack.global_demand || 0).toFixed(0)

          for (const material of tradepack.materials) {
            material.image = `https://cdn.tradetrack.online/items/${material.clientId}.png`;
            material.minLevel = globalMaterials[material.clientId] ? globalMaterials[material.clientId].minLevel : 1;
            let amount = material.amount;
            price += material.pricing.medianPrice * amount * tradepackCount;
            if (!metricData[material.clientId]) {
              let materialHistory = await loadItemMetric(material.clientId);

              if (materialHistory.found) {
                material.metrics = materialHistory;
              }
            } else {
              material.metrics = metricData[material.clientId];
            }
          }

          tradepacks.push({
            id: tradepack.id,
            name: tradepack.name,
            image: tradepack.url,
            price: price,
            materials: tradepack.materials,
            globalDemand: globalDemand,
            tradePosts: tradepack.tradeposts
          });

        }
        tradepacks = await processTradepacks(tradepacks);
        setTradepacks(tradepacks);
        setCrafting(crafting);
        setMetricData({});
        setFinishedLoading(true);
      } else {
        let tradepacksCopy = [...tradepacks];
        tradepacksCopy = await processTradepacks(tradepacksCopy);
        setTradepacks(tradepacksCopy);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bonus5, bonus10, certificate, tradepackCount, visualizeOption, typeChannel, pricesOption]);

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Top 5 tradepack routes</h5>
        <div className="mb-3">
          <label className="me-2" htmlFor="tradepostOrigin">Reputation bonuses: </label>
          <div className="form-check form-check-inline inline-checkbox">
            <input className="form-check-input" type="checkbox" id="5_bonus" onChange={onCheckboxChange} />
            <label className="form-check-label" htmlFor="5_bonus">
              5%
            </label>
          </div>

          <div className="form-check form-check-inline inline-checkbox">
            <input className="form-check-input" type="checkbox" id="10_bonus" onChange={onCheckboxChange} />
            <label className="form-check-label" htmlFor="10_bonus">
              10% |
            </label>
          </div>

          <div className="form-check form-check-inline inline-checkbox">
            <input className="form-check-input" type="radio" name="typeChannel" value="normal" id="typeChannelNormal" checked={typeChannel === 'normal'} onChange={onTypeChannelChange} />
            <label className="form-check-label" htmlFor="typeChannelNormal">
              Normal
            </label>
          </div>

          <div className="form-check form-check-inline inline-checkbox">
            <input className="form-check-input" type="radio" name="typeChannel" value="warmode" id="typeChannelWarmode" checked={typeChannel === 'warmode'} onChange={onTypeChannelChange} />
            <label className="form-check-label" htmlFor="typeChannelWarmode">
              Warmode
            </label>
          </div>

          <div className="form-check form-check-inline inline-checkbox">
            <input className="form-check-input" type="radio" name="typeChannel" value="plunder" id="typeChannelPlunder" checked={typeChannel === 'plunder'} onChange={onTypeChannelChange} />
            <label className="form-check-label" htmlFor="typeChannelPlunder">
              Plunder |
            </label>
          </div>

          <div className="form-check form-check-inline inline-checkbox">
            <input className="form-check-input" type="checkbox" id="certificate" onChange={onCheckboxChange} />
            <label className="form-check-label" htmlFor="certificate">
              Certificate |
            </label>
          </div>

          <div className="form-check form-check-inline inline-checkbox">
            <input className="form-check-input" type="radio" name="sea" value="all" id="allSea" checked={visualizeOption === 'all'} onChange={onVisualizeOptionChange} />
            <label className="form-check-label" htmlFor="allSea">
              All routes
            </label>
          </div>

          <div className="form-check form-check-inline inline-checkbox">
            <input className="form-check-input" type="radio" name="sea" value="only_sea" id="onlySea" checked={visualizeOption === 'only_sea'} onChange={onVisualizeOptionChange} />
            <label className="form-check-label" htmlFor="onlySea">
              Sea only
            </label>
          </div>

          <div className="form-check form-check-inline">
            <input className="form-check-input" type="radio" name="sea" value="ignore_sea" id="ignoreSea" checked={visualizeOption === 'ignore_sea'} onChange={onVisualizeOptionChange} />
            <label className="form-check-label" htmlFor="ignoreSea">
              Land only |
            </label>
          </div>

          <div className="form-check form-check-inline inline-checkbox">
            <input className="form-check-input" type="radio" name="prices" value="live" id="livePrice" checked={pricesOption === 'live'} onChange={onPricesOptionChange} />
            <label className="form-check-label" htmlFor="livePrice">
              Live Prices
            </label>
          </div>

          <div className="form-check form-check-inline inline-checkbox">
            <input className="form-check-input" type="radio" name="prices" value="7d" id="7dPrice" checked={pricesOption === '7d'} onChange={onPricesOptionChange} />
            <label className="form-check-label" htmlFor="7dPrice">
              Median From 7d
            </label>
          </div>

          <div className="form-check form-check-inline inline-checkbox">
            <input className="form-check-input" type="radio" name="prices" value="30d" id="30dPrice" checked={pricesOption === '30d'} onChange={onPricesOptionChange} />
            <label className="form-check-label" htmlFor="30dPrice">
              Median From 30d
            </label>
          </div>
          <div className="form-check form-check-inline">
            <select className="form-select" id="tradepackCount" onChange={(event) => setTradepackCount(event.target.value)}>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
            </select>
          </div>

          <button type="button" className="btn btn-primary" onClick={onRefresh} id="refresh">
            Refresh
          </button>

          <input type="text" className="form-control" id="newTradepackName" placeholder="New tradepack name" />
          <button type="button" className="btn btn-success" onClick={clickNewTradepack} id="newTradepack">
            Add new tradepack
          </button>
        </div>

        {
          !finishedLoading && <div className="d-flex justify-content-center">
            <div className="spinner-border m-5" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        }
        {
          finishedLoading && tradepacks.length > 0 &&

          <div className="mb-3" id="tradepacks">
            <table className="table table-dark table-striped table-bordered table-sm">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Materials</th>
                  <th scope="col">Crafting Price</th>
                  <th scope="col">Top 5 Routes</th>
                </tr>
              </thead>
              <tbody>
                {/* for in tradepacks */
                  tradepacks.map((tradepack, index) => {
                    return (
                      <tr key={index}>
                        <td className="align-middle">
                          <img src={tradepack.image} alt={tradepack.name} /> {tradepack.name} ({tradepack.globalDemand}%)
                        </td>
                        <td className="align-middle">
                          <ul>
                            {/* for in materials */
                              tradepack.materials.map((material) => {
                                let price = material.usingPrice;
                                price = price.toFixed(0);
                                price = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

                                let medianMaterial = material.median.toFixed(0);
                                medianMaterial = medianMaterial.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

                                let lastUpdated = new Date(material.pricing.lastUpdated).toLocaleString();
                                let medianPrice = material.pricing.medianPrice.toFixed(0);
                                let smallestPrice = material.pricing.smallestPrice.toFixed(0);
                                let highestPrice = material.pricing.highestPrice.toFixed(0);

                                const handleMouseEnter = async () => {
                                  // check if data is already loaded
                                  if (metricData[material.clientId]) {
                                    return;
                                  }
                                  let data = await loadItemMetric(material.clientId);

                                  if (data.found) {
                                    material.metrics = data;
                                    let prices = [];
                                    let labels = [];
                                    let median = 0;
                                    let cont = 1
                                    for (const metric of material.metrics.pricing) {
                                      // metric.price += material.taxToInfuse;
                                      prices.push(metric.price);

                                      median += metric.price;

                                      labels.push(
                                        cont
                                      );
                                      cont++;
                                    }


                                    median = median / material.metrics.pricing.length;

                                    // diff of last price and median
                                    let lastPrice = material.pricing.medianPrice;
                                    let diff = lastPrice - median;
                                    // percentage of diff
                                    let percentage = (lastPrice * 100) / median - 100;

                                    // add last price to prices
                                    prices.push(lastPrice);
                                    labels.push(cont);


                                    const lineData = {
                                      labels,
                                      datasets: [
                                        {
                                          drawActiveElementsOnTop: false,
                                          fill: true,
                                          pointStyle: false,
                                          tension: 0.4,
                                          label: 'Median Price',
                                          data: prices,
                                          borderColor: 'rgb(53, 162, 235)',
                                          backgroundColor: 'rgba(53, 162, 235, 0.5)',
                                        }
                                      ],
                                    };

                                    let metrics = {
                                      lineData: lineData,
                                      diff: diff,
                                      percentage: percentage,
                                      median: median
                                    }
                                    setMetricData(prevData => ({
                                      ...prevData,
                                      [material.clientId]: metrics
                                    }));
                                  }

                                };

                                const options = {
                                  responsive: true,
                                  animation: false,
                                  layout: {
                                    padding: 0
                                  },
                                  datasets: {
                                    line: {
                                      pointRadius: 0 // disable for all `'line'` datasets
                                    }
                                  },
                                  plugins: {
                                    legend: {
                                      position: 'top',
                                      display: false
                                    },
                                    title: {
                                      display: false,
                                      text: 'Median Price',
                                    },
                                    tooltip: {
                                      enabled: false
                                    }
                                  },
                                  scales: {
                                    y: {
                                      suggestedMin: 0,
                                      display: true
                                    },
                                    x: {
                                      display: false
                                    }
                                  }
                                };

                                const popover = (
                                  <Popover id="popover-basic" style={{ maxWidth: '550px' }}>
                                    <Popover.Header as="h3">
                                      <img src={material.image} alt={material.name} title={material.name} width={25} height={25} />
                                      {material.name}
                                    </Popover.Header>
                                    <Popover.Body>
                                      {metricData[material.clientId] ? (
                                        <>
                                          <div className="d-flex justify-content-between align-items-center">
                                            <div className="text-end">
                                              <h6>Median: <small>{medianPrice}</small></h6>
                                              <h6>Smallest: <small>{smallestPrice}</small></h6>
                                              <h6>Highest: <small>{highestPrice}</small></h6>
                                            </div>
                                            <div className="m-1">
                                              <Line options={options} data={metricData[material.clientId].lineData} />
                                            </div>
                                            <div className="flex-grow-1 d-flex flex-column align-items-center">
                                              <small>30d variation</small>
                                              <span className={metricData[material.clientId].diff > 0 ? 'text-danger h4' : 'text-success h4'}>
                                                {metricData[material.clientId].diff > 0 ? '+' : ''}
                                                {metricData[material.clientId].percentage.toFixed(2)}%
                                              </span>
                                            </div>
                                          </div>
                                          <div className="text-center">
                                            <small>Last 30 days median price: {metricData[material.clientId].median.toFixed(2)}. Last Updated: {lastUpdated}</small>
                                          </div>
                                        </>

                                      ) : (
                                        <div>Loading...</div>
                                      )}
                                    </Popover.Body>
                                  </Popover>
                                );

                                return (
                                  <>

                                    <div key={material.id} onMouseEnter={handleMouseEnter} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                      <button className="btn btn-danger btn-sm" data-tradepack={tradepack.id} data-material={material.clientId} style={{ padding: '1px 4px' }} onClick={removeMaterial} >-</button>
                                      <input
                                        type="number"
                                        min={0}
                                        name={"material_" + tradepack.id + "_" + material.clientId}
                                        data-tradepack={tradepack.id}
                                        data-material={material.clientId}
                                        value={material.amount}
                                        onChange={onChangeMaterialAmount}
                                        style={{ width: '60px' }} // ajuste de largura opcional
                                      />
                                      <OverlayTrigger trigger={["hover", "focus"]} placement="left-end" overlay={popover}>
                                        <div style={{ display: 'flex', alignItems: 'center', cursor: 'help' }}>
                                          {
                                            material.image && <img src={material.image} alt={material.name} title={material.name} width={25} height={25} />
                                          }                                          
                                          <span>{material.name} [{material.minLevel}] ({material.amount * tradepackCount} x {medianMaterial} = {price})</span>
                                        </div>
                                      </OverlayTrigger>


                                    </div>
                                  </>
                                );
                              })
                            }
                            {tradepack.newMaterial &&
                              <div>

                                <Creatable
                                  name={`newMaterial_${tradepack.id}`}
                                  options={Object.keys(materials).map((materialId) => {
                                    let material = materials[materialId];
                                    return {
                                      value: materialId,
                                      label: material.name
                                    }
                                  })}
                                  styles={customStyles}
                                />

                                <button className="btn btn-success btn-sm" data-tradepack={tradepack.id} style={{ padding: '1px 4px' }} onClick={addMaterial}  >Add Material</button>
                              </div>
                            }
                            {!tradepack.newMaterial &&
                              <button className="btn btn-success btn-sm" data-tradepack={tradepack.id} style={{ padding: '1px 4px' }} onClick={clickNewMaterial}  >+</button>
                            }
                          </ul>
                        </td>
                        <td className="align-middle text-center">{tradepack.price.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".")}</td>
                        <td>
                          <table className="table table-dark table-striped table-sm table-hover">
                            <thead>
                              <tr>
                                <th scope="col">Route</th>
                                <th scope="col">Demand</th>
                                <th scope="col">Current/Dijkstra</th>
                                <th scope="col">Sell Price</th>
                                <th scope="col">Profit</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                tradepack.bestRoutes.map((route) => {
                                  let profit = route.price - tradepack.price;
                                  profit = profit.toFixed(0);
                                  profit = profit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                                  let sellPrice = route.price.toFixed(0);
                                  sellPrice = sellPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

                                  return (
                                    <tr key={route.routeName}>
                                      <td>{route.routeName}</td>
                                      <td>{route.demand.toFixed(0)}%</td>
                                      <td>{route.distance} / {route.distanceDijkstra}</td>
                                      <td>{sellPrice}</td>
                                      <td>{profit}</td>
                                    </tr>
                                  );
                                })
                              }
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        }
      </div>
    </div>
  );
}

export default BestRoutesSimulator;
